<template>
  <Dialog
    :name="name"
    width="475px"
    class="dialog-edit-timeslot"
    @close="handleClearFields"
  >
    <template #title>
      <div style="font-size: 16px; font-weight: 400">Редактирование авто</div>
    </template>

    <div>
      <el-form
        ref="formAddTimeslot"
        :model="formAddTimeslot"
        :rules="rules"
        label-width="175px"
        class="dialog-edit-timeslot"
        @submit.native.prevent
      >
        <div class="dialog-edit-timeslot__left-section">
          <IqInputForm
            v-model="formAddTimeslot.plate_truck"
            prop="plate_truck"
            label="Номер авто"
            label-width="175px"
            :disabled="!isSuperAdmin"
            style="margin-bottom: 20px !important"
            @on-focus="$refs.formAddTimeslot.clearValidate('plate_truck')"
          />
          <IqInputForm
            v-model="formAddTimeslot.plate_trailer"
            prop="plate_trailer"
            label="Номер прицепа"
            label-width="175px"
            input-width="100%"
            disabled
            class="m-b-20"
          />
          <IqInputForm
            v-model="formAddTimeslot.truck_spindles_count"
            prop="truck_spindles_count"
            label="Количество осей"
            label-width="175px"
            input-width="100%"
            disabled
            class="m-b-20"
          />

          <IqSelectForm
            v-model="formAddTimeslot.type"
            label="Тип авто"
            prop="type"
            :rules="rules.type"
            :options="autoType"
            key-label="title"
            key-value="val"
            label-width="175px"
            input-width="100%"
            :is-super-important-label-width="true"
          />

          <IqSelectForm
            v-model="formAddTimeslot.culture_id"
            label="Культура"
            prop="culture_id"
            :rules="rules.culture_id"
            :options="preparedCultureList"
            key-label="name"
            key-value="id"
            label-width="175px"
            input-width="100%"
            :disabled="!isSuperAdmin"
            :is-super-important-label-width="true"
          />

          <IqInputForm
            v-if="!!formAddTimeslot.exporter_id"
            v-model="formAddTimeslot.exporter_name"
            label="Экспортер"
            label-width="175px"
            input-width="100%"
            disabled
          />

          <IqInputForm
            v-if="formAddTimeslot.supplier_id"
            v-model="formAddTimeslot.supplier_name"
            label="Поставщик"
            label-width="175px"
            input-width="100%"
            disabled
          />

          <template v-if="formAddTimeslot.common_supplier_inn">
            <IqInputForm
              v-model="formAddTimeslot.common_supplier_inn"
              label="Поставщик по ИНН"
              label-width="175px"
              input-width="100%"
              disabled
            />
            <div class="commonSupplier">
              {{ formAddTimeslot.common_supplier_name }}
            </div>
          </template>

          <IqInputForm
            v-model="formAddTimeslot.phone"
            v-mask="'+7 ### ###-##-##'"
            label="Телефон водителя"
            prop="phone"
            label-width="175px"
            input-width="100%"
            :rules="rules.phone"
            class="m-b-20"
          />

          <IqInputForm
            v-model="formAddTimeslot.driver_name"
            label="ФИО водителя"
            prop="name"
            label-width="175px"
            input-width="265px"
            :rules="rules.driver_name"
            class="m-b-20"
          />

          <IqSelectForm
            v-model="formAddTimeslot.detach"
            label="Проезд"
            prop="detach"
            :rules="rules.detach"
            :options="[
              { id: 0, name: 'Разрешен' },
              { id: 1, name: 'Отказан' },
            ]"
            key-label="name"
            key-value="id"
            label-width="175px"
            input-width="265px"
            :is-super-important-label-width="true"
          />
        </div>

        <div class="dialog-edit-timeslot__right-section">
          <div class="w-100p">
            <IqTitle class-name="mobileHeader">
              Выберите дату и время:
            </IqTitle>
          </div>

          <el-form-item prop="date">
            <el-date-picker
              ref="datePicker"
              v-model="formAddTimeslot.date"
              type="date"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              placeholder=""
              :picker-options="pickerOptions"
              size="small"
              class="dialog-edit-timeslot__date-picker w-284"
            />
          </el-form-item>

          <el-form-item prop="time">
            <el-time-select
              v-model="formAddTimeslot.time"
              size="small"
              :picker-options="{
                start: '00:15',
                step: '00:15',
                end: '24:00',
              }"
              placeholder="Время"
              class="dialog-edit-timeslot__time-picker"
            />
          </el-form-item>
        </div>

        <div class="df jc-fe ai-c w-100p m-t-7">
          <div class="dialog-edit-timeslot__right-section">
            <IqButton
              :loading="isLoadingPostTimeslots"
              class="w-100p"
              @onClick="handleAddNewTimeslot"
            >
              Изменить тайм слот
            </IqButton>
          </div>
        </div>
      </el-form>
    </div>
  </Dialog>
</template>

<script>
import { GET_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import {
  GET_IS_LOADING_POST_TIMESLOTS,
  UPDATE_TIMESLOT,
} from '@/views/manager/store/actions'
import { GET_TERMINAL_CURRENT_REQUIRE_INN } from '@/store/actions'
import { GET_TIME } from '@/constants/date'
import { STEP_MANAGER_EDIT_TIMESLOT } from '@/constants/dialogs'
import { autoType } from '@/constants/auto-type'
import { getTzDate, getTzTime, getUnixFromTzDate, pickerOptions } from '@/core'
import { mapActions, mapGetters } from 'vuex'
import { ruleFormAddTimeslot } from '@/views/manager/components/dialogs/dialog-edit-timeslot/data/dialogEditTimeslot'
import Dialog from '@/UI/dialog/Dialog'
import IqButton from '@/UI/buttons/IqButton'
import IqInputForm from '@/UI/form/input/IqInputForm'
import IqSelectForm from '@/UI/select/IqSelectForm'
import IqTitle from '@/UI/typography/IqTitle'
export default {
  name: 'StepDialogEditTimeslot',
  components: {
    Dialog,
    IqInputForm,
    IqSelectForm,
    IqTitle,
    IqButton,
  },
  data() {
    return {
      name: STEP_MANAGER_EDIT_TIMESLOT,
      preparedCultureList: [],
      formAddTimeslot: {
        plate_truck: '',
        culture_id: '',
        exporter_id: '',
        supplier_id: '',
        phone: '',
        date: null,
        time: null,
        type: null,
        detach: null,
        plate_trailer: '',
        common_supplier_name: '',
        common_supplier_inn: '',
        common_supplier_id: '',
        truck_spindles_count: '',
        driver_name: '',
      },
      rules: ruleFormAddTimeslot,
      pickerOptions,
      autoType,
    }
  },
  computed: {
    ...mapGetters({
      isLoadingPostTimeslots: GET_IS_LOADING_POST_TIMESLOTS,
      cultureList: GET_CULTURE_FROM_STATE,
      terminalRequireInn: GET_TERMINAL_CURRENT_REQUIRE_INN,
    }),

    dialogData() {
      return this.getDialog(this.name)?.data || {}
    },
  },
  watch: {
    cultureList: {
      handler(value) {
        if (value) {
          this.preparedCultureList = value.map(item => ({
            id: item.id,
            unload_id: item.unload_id,
            name: `${item.name} ${
              item.harvest_year ? item.harvest_year + ' года' : ''
            }`,
            harvest_year: `${item.harvest_year}`,
          }))
        }
      },
      deep: true,
      immediate: true,
    },

    dialogData(val) {
      if (Object.keys(val).length > 0) {
        this.setFormFields(
          val.culture_id,
          val.num_auto,
          val.phone,
          getTzDate(val.time * 1000),
          getTzTime(val.time * 1000, GET_TIME),
          val.truck_type_code,
          val.truck_status === 'detached' ? 1 : 0,
          val.exporter_id,
          val.exporter_name,
          val.supplier_id,
          val.supplier_name,

          val.plate_trailer,
          val.extras.step !== null
            ? val.extras.common.common_supplier_name
            : '',
          val.extras.step !== null ? val.extras.common.common_supplier_inn : '',
          val.extras.step !== null ? val.extras.common.common_supplier_id : '',
          val.extras.step !== null ? val.extras.step.truck_spindles_count : '',
          val.driver_name,
        )
      }
    },
  },
  methods: {
    ...mapActions({
      updateTimeslot: UPDATE_TIMESLOT,
    }),
    handleAddNewTimeslot() {
      this.$refs.formAddTimeslot.validate(valid => {
        if (valid) {
          const requestData = {
            timeslot_id: this.dialogData.id,
            time: getUnixFromTzDate(
              this.formAddTimeslot.date,
              this.formAddTimeslot.time,
            ),
            plate_trailer: this.formAddTimeslot.plate_trailer,
            common_supplier_name: this.formAddTimeslot.common_supplier_name,
            common_supplier_inn: this.formAddTimeslot.common_supplier_inn,
            truck_spindles_count: this.formAddTimeslot.truck_spindles_count,
            plate_truck: this.formAddTimeslot.plate_truck,
            detach: this.formAddTimeslot.detach,
            common_supplier_id: this.formAddTimeslot.common_supplier_id,
            culture_id: this.formAddTimeslot.culture_id,
            exporter_id: this.formAddTimeslot.exporter_id,
            supplier_id: this.formAddTimeslot.supplier_id,
            phone: this.formAddTimeslot.phone,
            truck_type_code: this.formAddTimeslot.type,
            setFormFields: this.setFormFields,
            nameDialog: this.name,
            name: this.formAddTimeslot.driver_name,
          }

          if (this.formAddTimeslot.detach === 1) {
            requestData.detach = 1
          } else {
            requestData.restore = 1
          }

          this.updateTimeslot(requestData)
        }
      })
    },
    setFormFields(
      culture_id = '',
      plate_truck = '',
      phone = '',
      date = null,
      time = null,
      type = null,
      detach = null,
      exporter_id = null,
      exporter_name = null,
      supplier_id = '',
      supplier_name = '',
      plate_trailer = '',
      common_supplier_name = '',
      common_supplier_inn = '',
      common_supplier_id = '',
      truck_spindles_count = '',
      name = '',
    ) {
      this.formAddTimeslot.culture_id = culture_id
      this.formAddTimeslot.plate_truck = plate_truck
      this.formAddTimeslot.phone = phone
      this.formAddTimeslot.date = date
      this.formAddTimeslot.time = time
      this.formAddTimeslot.type = type
      this.formAddTimeslot.detach = detach
      this.formAddTimeslot.exporter_id = exporter_id
      this.formAddTimeslot.exporter_name = exporter_name
      this.formAddTimeslot.supplier_id = supplier_id
      this.formAddTimeslot.supplier_name = supplier_name
      this.formAddTimeslot.plate_trailer = plate_trailer
      this.formAddTimeslot.common_supplier_name = common_supplier_name
      this.formAddTimeslot.common_supplier_inn = common_supplier_inn
      this.formAddTimeslot.common_supplier_id = common_supplier_id
      this.formAddTimeslot.truck_spindles_count = truck_spindles_count
      this.formAddTimeslot.driver_name = name
    },
    handleClearFields() {
      this.setFormFields()
      this.$refs.formAddTimeslot.clearValidate()
    },
  },
}
</script>

<style lang="sass">
.dialog-edit-timeslot
  display: flex
  justify-content: space-between
  align-items: flex-start
  flex-wrap: wrap
  padding-bottom: 15px
  &__date-picker
    width: 284px!important

  &__left-section,
  &__right-section
    width: 100%
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    justify-content: flex-start
    &--btn
      justify-content: center

  &__right-section
    .el-form-item__content
      margin-left: 0 !important

  &__timeslot-heading
    flex: 0 0 100%
    margin-top: 0

  &__timeslot-wrap-btn
    flex: 0 0 100%
    display: flex
    justify-content: center

  &__btn-wrap
    margin-left: 30px
  &__btn
    font-size: 28px
    cursor: pointer
    color: $color-black
    transition: all .3s ease
    padding: 0 !important
    border: none
    &:hover, &:focus
      color: $btn-orange
      background: transparent

  &__message
    color: $color-red
    margin-bottom: $space-16
    font-weight: bold

  &__param-item
    flex: 0 0 100%
    display: flex
    justify-content: flex-start
    align-items: center
    & + &
      margin-top: 10px

  &__param-title
    flex: 0 0 40%
    font-weight: bold

  &__param-val
    flex: 0 0 60%

  &__time-picker
    width: 100px !important
    margin-left: 20px

  .el-form-item__content
    position: static

  .el-form-item
    position: relative
</style>
